<script setup lang="ts">
import { CmsBlock, CmsSlot } from "@shopware-pwa/types";

type CmsBlockTeaserInteractive = CmsBlock & {
  slots: Array<
    CmsSlot & {
      slot: "content";
    }
  >;
};

const props = defineProps<{
  content: CmsBlockTeaserInteractive;
}>();

const { getSlotContent } = useCmsBlock(props.content);
const slot: any = getSlotContent("teaserCategory");

const { getConfigValue } = useCmsElementConfig(slot);
const teasers: any = getConfigValue("objItems");
</script>

<template>
  <div class="group/teaserinteractive">
    <div class="c-width-default relative flex h-[80vh] max-h-[765px] min-h-[600px] flex-col lg:flex-row">
      <div
        v-for="teaser in teasers"
        :key="teaser.sort_id"
        class="overflow-hidden transition-all duration-500 hover:h-full lg:h-full lg:hover:!h-full lg:hover:w-full lg:group-hover/teaserinteractive:h-full"
        :class="{
          'h-full w-full': teasers.length === 1,
          'h-1/2 hover:!h-2/3 group-hover/teaserinteractive:h-1/3 lg:w-1/2': teasers.length === 2,
          'h-1/3 hover:!h-2/3 group-hover/teaserinteractive:h-1/6 lg:w-1/3': teasers.length === 3,
        }"
      >
        <CmsElementTeaserInteractive
          :position="teaser.position"
          :align="teaser.align"
          :background-color="teaser.backgroundColor"
          :color="teaser.color"
          :shadow="teaser.shadow"
          :title="teaser.title"
          :subtitle="teaser.subtitle"
          :text="teaser.text"
          :buttons="teaser.buttons"
        >
          <SharedCmsImage
            v-if="teaser.media?.id"
            :crop="teaser?.media?.crop"
            :focus-point="teaser?.media?.focusPoint"
            :src="teaser?.media?.url"
            :alt="teaser.title"
            width="1920"
            height="1020"
            class-img="h-full w-full object-cover"
          />
        </CmsElementTeaserInteractive>
      </div>
    </div>
  </div>
</template>
